import React, { Suspense,useEffect,useState,useRef } from 'react';
import LazyLoad, { forceVisible } from 'react-lazyload';


import "./index.css";
import Loading from "./components/GlobalComponents/Loading";
import Admin from './components/Admin/admin';


const ExitPopUp = React.lazy(() => import('./components/PopUps/ExitPopUp'));
const Associations = React.lazy(() => import('./components/Associations/Associations'));
const ContactUsButtons = React.lazy(() => import("./components/ContactUsSection/ContactUsButtons"));
const TopBar = React.lazy(() => import('./components/TopBar/TopBar'));
const HowItWorks = React.lazy(() => import('./components/HowSection/HowItWorks'));
const QuoteWidget = React.lazy(() => import('./components/QuoteGenerator/QuoteWidget'));
const FileUploader = React.lazy(() => import ('./components/PopUps/FileUploader'));
const PhonePopUp = React.lazy(() => import('./components/PopUps/PhonePop'));
const WhyHire = React.lazy(() => import('./components/WhySection/WhyHire'));
//const BankSupport = React.lazy(() => import('./components/BankSection/BankSupport'));
const ChatBubble = React.lazy(()=> import('./components/ChatBubble/ChatBubble'));
const ContactUs = React.lazy(() => import('./components/ContactUsSection/ContactUs'));
const Footer = React.lazy(() => import('./components/Footer/Footer'));
const LearningCenter = React.lazy(() => import('./components/LearningCenter/LearningCenter'));
const AiChat = React.lazy(() => import('./components/AiChat/AiChat'));
const Cities = React.lazy(() => import('./components/CitiesSection/CitiesWeSupportUpdated'));
const Notification = React.lazy(() => import ('./components/Notifications/Notification'));
const AboutUs = React.lazy(() => import ('./components/AboutUs/AboutUs'));
const PopUpVideo = React.lazy(() => import ('./components/LearningCenter/PopUpVideo')); 
const CompareService = React.lazy(()=> import ('./components/Compare/CompareService'));

function App(){
const TF_TYPE = {
    'j0qIlCyE':{//call
        ae_id_opened: '08e48ca552035f4d929740ba9b3bd7a7',
        ae_id_abandoned: '63354f0488909ec7d157ab998b8ddde3',
        ae_id_completed: '336337430a577892c11141525d7cea49'
    },
    'L4Oz5tQU' : { //Text
        ae_id_opened: 'c027f45f40db29e2561e45f565b88936',
        ae_id_abandoned: '50b86a49590b83cc89864508613bdb6c',
        ae_id_completed: '2450d2808b9ad2e63d7b3b239d504a8e'
    },
    'ydLbwjXd':{//chat
        ae_id_opened: '2cfc38266507e2579952c20eebce6c20',
        ae_id_abandoned: 'ffad77b20f9c549bd9c7be6347b51f8e',
        ae_id_completed: '4b0ce89730e66cfd7e6ddf425e6322d8'
    },
    'uPRfedSM': {//email
        ae_id_opened: 'e7186d6d57523b8bc768cb5c8002042b',
        ae_id_abandoned: '54a44c5dac95361db730d6af46189338',
        ae_id_completed: '91096a6b04dab5b60ecf5d9a32d61794'
    },
    'JaeQdOOd': { //promo 
        ae_id_opened: '6e648032277c2bf8d04595affa92ea8b',
        ae_id_abandoned: 'ed886774cc9ec2742bb73c62eb32d157',
        ae_id_completed: 'e24aca8f3873513840f6da5441235e0e'
    },
    'itK2CoOn': { //Save 
        ae_id_opened: '266d91c20457f84ec56240b1d3cac5b7',
        ae_id_abandoned: 'ed886774cc9ec2742bb73c62eb32d157',
        ae_id_completed: 'e24aca8f3873513840f6da5441235e0e'
    },
    'O9Supl2D': {//Hire
        ae_id_opened: '67ec9c9953e660847e9b695d9857e93b',
        ae_id_abandoned: 'e20473c944e4975208f8f3c6617ebef7',
        ae_id_completed: '24212a1da022577f1d902fd4c1189b08'
    },
    'QMm0Ap': {//TERM NOT REAL
        ae_id_opened: '08e48ca552035f4d929740ba9b3bd7a7',
        ae_id_abandoned: '63354f0488909ec7d157ab998b8ddde3',
        ae_id_completed: '336337430a577892c11141525d7cea49'
    }
};
    
    const appRef = useRef(null)
    const [scrolled, setscrolled] = useState(false);
    const [userYScroll,setUserYScroll] = useState(0);
    const [popUpNext, setPopUpNext] = useState(false);
    
    const [popUpComp,setPopUpComp] = useState(false);
    
    
    const [form, setForm] = useState(null);
    let fastScrolled = false;
    
    const [terms_deep,set_terms_deep] = useState(false);
    const [privacy_deep,set_privacy_deep] = useState(false);
    const [data_deep,set_data_deep] = useState(false);
    const [hire_deep,set_hire_deep] = useState(false);
    const [quote_pdf_deep,set_quote_pdf_deep] = useState(false);
    const [upload_deep,set_upload_deep] = useState(false);
    
    const [market_copy_info,set_market_copy_info] = useState({"render":false});
    const [marketing_copy_links,set_marketing_copy_links] = useState(false);


    const [video_open,set_video_open] = useState(false);
    const [curr_video,set_curr_video] = useState({});
    const [video_cue,set_video_cue] = useState([]);
    const [learning_resources,set_learning_resources] = useState(null);
    
    //ChatBubble
    const [chatBubbleOpen,setChatBubbleOpen] = useState(false);
    const [chatBubbleExpanded,setChatBubbleExapnded] = useState(true);
    
    const chatBubbleAnimationTime = 100;
    
    function openChatBubble(){
        if(!chatBubbleOpen){
            animate_chat_bubble();
            setTimeout(()=>{
                setChatBubbleOpen(true);    
            },chatBubbleAnimationTime);
        }
    }
    window.showChatWindow = openChatBubble;
    
    function closeChatBubble(){
        animate_chat_bubble();
        setTimeout(()=>{
            setChatBubbleOpen(false);    
        },chatBubbleAnimationTime);
    }
    
    function forceCloseBubble(){
        setChatBubbleOpen(false);
    }

    
    function animate_chat_bubble(){
        setChatBubbleExapnded(false);
        setTimeout(()=>{
            setChatBubbleExapnded(true);
        },chatBubbleAnimationTime);
    }
    
    function determine_ab_testing_url(){
        var ab_test_js_url = {
          'dev': `https://mc.cloudlaw.ca/json?ts=${Date.now()}`,
          'staging': `https://assets.cloudlaw.ca/json/mc_below_fold.staging.json?ts=${Date.now()}`,
          'prod': `https://assets.cloudlaw.ca/json/mc_below_fold.json?ts=${Date.now()}`,
        };

        return ab_test_js_url[window.environment];
    }
    
    function determine_learning_resources_url(){
        var learning_resources_url = {
          'dev': `https://lr.cloudlaw.ca/ejson?ts=${Date.now()}`,
         // 'dev': `https://assets.cloudlaw.ca/json/learning_resources.ejson`,
          'staging': `https://assets.cloudlaw.ca/json/learning_resources.staging.ejson?ts=${Date.now()}`,
          'prod': `https://assets.cloudlaw.ca/json/learning_resources.ejson?ts=${Date.now()}`
        }; 
         return learning_resources_url[window.environment];
    }
    
    function determine_compare_table_url(){
        var compare_resources_url = {
          'dev': `https://mc.cloudlaw.ca/mc_compare_table.ejson?ts=${Date.now()}`,
          'staging': `https://assets.cloudlaw.ca/json/mc_compare_table.staging.ejson?ts=${Date.now()}`,
          'prod': `https://assets.cloudlaw.ca/json/mc_compare_table.ejson?ts=${Date.now()}`,
        }; 
         return compare_resources_url[window.environment];
    };
    
    function determine_ai_chat_url(){
        var ai_chat_url = {
          'dev': `https://ai.cloudlaw.ca/build.ejson?ts=${Date.now()}`,
          //'dev': `https://assets.cloudlaw.ca/json/ai_chat.ejson?ts=${Date.now()}`,
          'staging': `https://assets.cloudlaw.ca/json/ai_chat.staging.ejson?ts=${Date.now()}`,
          'prod': `https://assets.cloudlaw.ca/json/ai_chat.ejson?ts=${Date.now()}`,
        }; 
         return ai_chat_url[window.environment];
    }
    
    function determine_notifications_url(){
        var noti_url = {
          'dev': `https://assets.cloudlaw.ca/json/notifications.staging.json?ts=${Date.now()}`,
          'staging': `https://assets.cloudlaw.ca/json/notifications.staging.ejson?ts=${Date.now()}`,
          'prod': `https://assets.cloudlaw.ca/json/notifications.ejson?ts=${Date.now()}`,
        }; 
         return noti_url[window.environment];
    }
    
    function determine_exit_intent_url(){
        var exit_url = {
          //'dev': `https://assets.cloudlaw.ca/json/exit_intent.staging.json?ts=${Date.now()}`,
          'dev': `https://ei.cloudlaw.ca/build.json?ts=${Date.now()}`,
          'staging': `https://assets.cloudlaw.ca/json/exit_intent.staging.json?ts=${Date.now()}`,
          'prod': `https://assets.cloudlaw.ca/json/exit_intent.json?ts=${Date.now()}`,
        }; 
         return exit_url[window.environment];
    }
    
    //determine_cities_url on line 1861 of googleMap.js 
    

    
    async function handleScroll(){
        setUserYScroll(document.documentElement.scrollTop);
        if(document.documentElement.scrollTop == 0){
            document.getElementById('quoteBubbleBubble').style.display = 'none';
        }else{
            document.getElementById('quoteBubbleBubble').style.display = 'block';
        }
        if(!scrolled && !fastScrolled && !window.scrolled){

            fastScrolled = true;
            setscrolled(true);
            window.scrolled = true;
            if(window.location.hash == ''){
                window.history.pushState({id:1},null,'#Home');
            }
            document.getElementById('quoteBubbleButton').style.animation = 'bubbleShake 2s infinite'
            window.addEventListener('hashchange', function(){
                if(window.location.hash == ''){
                    window.history.replaceState({id:0},null,'');
                    //setTimeout(()=>CheckUserLeave({clientY:-1,clientX:0}),10);
                }
            });
            
            Promise.all([window.load_deferred_css('/css/BubbleWidgetStyle.css'),window.load_deferred_js('/js/chatBubble.js'),window.load_google_autocomplete_js()]).then(()=>{
                if(document.getElementById('SeeMoreBubble')){
                    document.getElementById('SeeMoreBubble').remove();
                }
            });
            
            const promises = [window.load_deferred_js('https://www.youtube.com/iframe_api'),load_learning_resources(),load_marketing_copy_elements()]
            await Promise.all(promises);
        }
    }
    
    
    async function load_learning_resources(){
        try{
            //const res = await fetch(determine_learning_resources_url());
            // learning_resources = await res.json();

            const learning_resources = await window.load_deferred_json(determine_learning_resources_url());
            
            window.learning_resources = learning_resources;
            //console.log('LEARNING RESOURCES LOADED');
            set_learning_resources(learning_resources);
            return learning_resources;
        }catch(e){
            console.log(`COULDN'T LOAD LEARNING RESOURCES: ${e}`);
            return {};
        }
    }
    
    async function load_marketing_copy_elements(full_refresh){
        try{
            if(full_refresh){
                await window.refresh_above_fold_market_copy_elements();
            }
            
            //const res = await fetch(determine_ab_testing_url());
            //const marketing_copy_elements = await res.json();
            const marketing_copy_elements = await window.load_deferred_json(determine_ab_testing_url(),'GET',true);
            
            
            if(window.marketing_copy_elements){
                window.marketing_copy_elements = merge_marketing_elements(window.marketing_copy_elements,marketing_copy_elements);
            }else{
                window.marketing_copy_elements = marketing_copy_elements;  
            }  
            
            window.toggle_marketing_copy_version = ()=>{
                let new_version = 'a';
                if(window.marketing_copy_variation == 'a'){
                    new_version = 'b';
                }
                window.toggle_above_fold_copy(new_version);
                set_market_copy_info({
                    "render": true,
                    "enabled":true,
                    "marketing_copy_variation":new_version
                });
                window.marketing_copy_variation = new_version;
                //console.log(`MARKETING COPY CHANGED TO: ${new_version}`);
            };
            
            window.toggle_marketing_copy_links = ()=>{
                window.marketing_copy_links = !window.marketing_copy_links;
                set_marketing_copy_links(window.marketing_copy_links);
                window.toggle_above_fold_editor_borders(window.marketing_copy_links);
               // console.log(`MARKETING COPY ELEMENT LINKS: ${window.marketing_copy_links}`);
            };
            window.marketing_copy_links = false;
            
            set_market_copy_info({
                "render": true,
                "enabled":true,
                "marketing_copy_variation": window.marketing_copy_variation
            });
            //console.log('MARKETING COPY LOADED');
        }catch(e){
            window.marketing_copy_elements = {};
            console.log(`MARKETING COPY FAILED: ${e}`); 
            set_market_copy_info({
                "render": true,
                "enabled":false,
            });
        }
    }
    
    function merge_marketing_elements(old_elems,new_elems){
        let old_keys = Object.keys(old_elems);
        let new_keys = Object.keys(new_elems);
        
        let new_obj = {};
        
        old_keys.forEach((ok)=>{
            if(old_elems[ok].element_position == 'above_fold'){
                new_obj[ok] = old_elems[ok];
            }
        });
        
        new_keys.forEach((nk)=>{
            new_obj[nk] = new_elems[nk];
        });
        return new_obj;
    }
    
    window.load_marketing_copy_elements = load_marketing_copy_elements;
    
    useEffect(()=>{
        window.load_all = forceVisible;
        
        if(document.getElementById('SeeMoreBubble')){
            document.getElementById('SeeMoreBubble').addEventListener('click',()=>{
                let url = new URL(document.URL);
                url.hash = '#HowItWorks';
                document.location.href = url; 
            });
        }
        
        window.document.addEventListener('input',(e)=>{
            if(e.target.id == 'exitLeadPhone'){
                document.getElementById('exitPhoneError').style.visibility = 'hidden';
                let val = e.target.value;
                if(e.data != null){
                    if(isNaN(parseInt(e.data))){
                        e.target.value = val.slice(0, val.length - 1);
                    }else if(val.length == 3 || val.length == 7){
                        e.target.value += '-';
                    }else if(val.length >= 12){
                         e.target.value = val.slice(0, 12);
                    }
                }
                
            }
        });
        //window.document.addEventListener('mouseleave',CheckUserLeave);
        window.addEventListener('scroll',handleScroll);
        //window.addEventListener('resize',handleResize);
        
        document.getElementById('quoteBubbleButton').addEventListener('click',()=>{
           if(!window.quoteGenerated && window.is_recent_quote_defaults_only()){
               goToHash('#Instant-Quote');
           }
        });
        
        
        if(window.privacy_link){//privacy
            goToHash("#Footer");
            set_privacy_deep(true);
            window.privacy_link = null;
        }else if(window.terms_link){//terms
            goToHash("#Footer");
            set_terms_deep(true);
            window.terms_link = null;
        }else if(window.data_link){
            goToHash("#Footer");
            set_data_deep(true);     
            window.data_link = null;
        }else if(window.hire_link){
            goToHash("#Instant-Quote");
            set_hire_deep(true);
            window.hire_link = null;
        }else if(window.quote_pdf_link){
            goToHash("#Instant-Quote");
            set_quote_pdf_deep(true);
            window.quote_pdf_link = null;           
        }else if(window.upload_link){
            goToHash("#Instant-Quote");
            set_upload_deep(true);
            window.upload_link = null;     
        }else if(window.start_link){
            goToHash("#Instant-Quote");
            set_upload_deep(true);
            window.start_link = null;     
        }else if(window.quote_section_link){
            window.quote_section_link = null;
            goToHash("#Instant-Quote");
        }else if(window.call_form_link){
            openCall();
        }else if(window.text_form_link){
            openText();
        }
        
        
        function openCall(){
            if(window.exitIsReady){
                window.createExitPopup("116eefb6ff474998ac15cd4a2108d617");
            }else{
                setTimeout(openCall,100);
            }
        }
        
        
        function openText(){
            if(window.exitIsReady){
                window.createExitPopup("95179190d1264d298454391f964def99");
            }else{
                setTimeout(openText,100);
            }
        }
        
        
        if(document.getElementById('full_screen_over_gray')){
            document.getElementById('full_screen_over_gray').addEventListener('touchmove', function(event) {
              event.preventDefault();
            }, { passive: false });
        }
        
        if(document.getElementById('init_article_learn_button')){
            document.getElementById('init_article_learn_button').addEventListener('click', function(event) {
              goToHash('Learning-Center');
            });
        }
        
        setTimeout(handleScroll,1000);
        
    },[]);
    
    useEffect(() => {
      if (form) {
        form.toggle();
        document.querySelector(".tf-v1-iframe-wrapper").style.width = "100%";
        document.querySelector(".tf-v1-iframe-wrapper").style.height = "100%";
        document.querySelector(".tf-v1-iframe-wrapper").style.maxWidth  = "800px";
      }
    }, [form]);
   

   
    function createForm(id='', quoteUUID=''){
        console.log(id)
      window.form_open = true;
      let submitted = false;
      //setTopState('closeForm');
      window.topState = 'closeForm';
      
      Promise.all([window.load_deferred_js('/vendor/typeform/embed.js'),window.load_deferred_css('/vendor/typeform/slider.css')]).then(()=>{
        
        var skip_email = false;
        if(window.get_lead_email() != null){
            skip_email = true;
        }
        
        var skip_phone = false;
        if(window.get_lead_phone() != null){
            skip_phone = true;
        }
        
        var tf_url = `https://form.typeform.com/to/${id}?skip_email=${skip_email}&skip_phone=${skip_phone}`;
        setForm(window.tf.createSlider(tf_url,{
           position: 'left',
            onSubmit: (event) => {
                submitted = true;
                window.login_tf_lead(id, event.response_id,window.get_lead_phone(),window.get_lead_email());
            },
            
            onClose:()=>{
                window.topState = 'regular';
            }
        }));
      }); 
    }

    
    function closeForm(form){
        window.form_open = false;
        if(form){
            form.close();
        }
        document.body.style.overflowY = 'scroll';
    }
    
     
     function goToHash(hash){
        handleScroll();
        forceVisible();
        waitToGo(hash);
        function waitToGo(hashWait){
            if(window.quote_full_loaded && window.learning_resources && window.marketing_copy_elements){
                if(window.location.hash == hashWait){
                   window.location.hash = '';
                }
                window.location.hash = hashWait;
                
            }else{
                setTimeout(()=>waitToGo(hashWait), 100);
            }
        }
    }
    
    window.goToHash = goToHash;
    
    const [phonePopOpen,setPhonePopOpen] = useState(false);
    
    function openVideo(video,newVideoCue){
        if(window.openQuoteBubble && window.innerWidth < 801){
            window.openQuoteBubble();
        }
        
        set_video_open(true);
        if(newVideoCue){
            set_video_cue(newVideoCue);
        }else{
            set_video_cue([]);
        }

    }

    let learning_resources_map = {};
    if(learning_resources){
        learning_resources.forEach((lr)=>{
           if(lr && lr.video_id){
                learning_resources_map[lr.video_id] = lr;  
           } 
        });
    }
    
    
    function close_video(force){
        if(video_cue.length > 0 && learning_resources_map[video_cue[0]] && !force){
            const new_cue = [...video_cue];
            new_cue.shift();
            openVideo(learning_resources_map[video_cue[0]],new_cue);
            set_curr_video(learning_resources_map[video_cue[0]]);
            return 1;
        }else{
            set_video_cue([]);
            set_video_open(false);
            return 0;
        }
    }
    //openMenu={openMenu}
    //closeMenu={closeMenu}
    return(
        <div id='app' ref={appRef}>
        
            <PhonePopUp open={phonePopOpen} setPhonePopOpen={setPhonePopOpen}/>        
            <div className='hidden top-0 z-[100] h-[100vh] w-[100vw] bg-black bg-opacity-80 fixed overflow-y-hidden' id='full_screen_over_gray'></div>
            {
            window.admin_mode ?
                <Admin></Admin>
                :
                <div/>
            }
            
            <ChatBubble userYScroll={userYScroll} open={chatBubbleOpen} openChatBubble={openChatBubble} closeChatBubble={closeChatBubble} chatBubbleExpanded={chatBubbleExpanded} scrolled={scrolled}/>
            {scrolled && false &&
                <Suspense fallback={<Loading></Loading>}>
                    <Notification determine_notifications_url={determine_notifications_url}/>
                </Suspense>  
            }
            
            <Suspense fallback={<Loading></Loading>}>
                <ExitPopUp determine_exit_intent_url={determine_exit_intent_url}/>
            </Suspense> 
            
            <div className="overflow-x-hidden bg-white">
                <Suspense fallback={<Loading height={'h-[100px]'}></Loading>}>
                    <TopBar market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links} form={form} openForm={createForm} closeForm={closeForm}   goToHash={goToHash}></TopBar>
                </Suspense>
                
                <Suspense fallback={<Loading height={'h-[100px]'}></Loading>}>
                    <PopUpVideo set_video_open={set_video_open} video={curr_video} open={video_open} onClose={close_video}></PopUpVideo>
                </Suspense>
                
                <hr id='How-It-Works' className="h-0.5 bg-white border-white mb-[10px] border-white"></hr>
                <LazyLoad height={1303} once>
                    <Suspense fallback={<Loading height={'h-[1303px]'}></Loading>}>
                        {market_copy_info.render ?
                            <HowItWorks market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links}></HowItWorks>
                            :
                            <div className='h-[1355px]'></div>
                        }
                    </Suspense>    
                </LazyLoad>
                
                
                <hr id='Instant-Quote' className="h-0.5 bg-white border-white mb-16"></hr>
                <LazyLoad height={847} once>
                    <Suspense fallback={<Loading height={'h-[847px]'}></Loading>}>
                        {market_copy_info.render && learning_resources ?
                        <div>
                        <FileUploader upload_deep={upload_deep} market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links}></FileUploader>
                        <QuoteWidget userYScroll={userYScroll} market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links} closeChatBubble={forceCloseBubble} close_video={close_video} openVideo={openVideo} video_open={video_open} set_video_open={set_video_open} curr_video={curr_video} learning_resources={learning_resources} set_curr_video={set_curr_video} openForm={createForm} setPhonePopOpen={setPhonePopOpen} phoneOpen={phonePopOpen}  hire_deep={hire_deep} quote_pdf_deep={quote_pdf_deep}></QuoteWidget>
                        </div>
                        :
                        <div className='h-[0px]'></div>
                        }
                    </Suspense>             
                </LazyLoad> 
                
                <hr id='Compare' className="h-0.5 bg-white border-white mb-16 bg-slate-200"></hr>
                <LazyLoad height={1355} once>
                    <Suspense fallback={<Loading height={'h-[500px]'}></Loading>}>
                        {market_copy_info.render && learning_resources  ?
                            <CompareService  openVideo={openVideo} determine_compare_table_url={determine_compare_table_url} market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links} learning_resources={learning_resources} set_curr_video={set_curr_video}></CompareService>
                            : 
                            <div className='h-[500px]'></div>
                        }
                    </Suspense>    
                </LazyLoad>

                

                
                <hr id='Why-Hire' className="h-0.5 bg-white border-white mb-16 bg-slate-200"></hr>
                <LazyLoad height={1262} once>
                    <Suspense fallback={<Loading height={'h-[1262px]'}></Loading>}>
                    {market_copy_info.render ?
                        <WhyHire market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links}></WhyHire>
                        :
                        <div className='h-[1262px]'></div>
                    }
                    </Suspense>    
                </LazyLoad>
                
            
                
                <hr id='Learning-Center' className="h-0.5 bg-white border-white mb-16 bg-slate-200"></hr>
                <LazyLoad height={420} once>
                    <Suspense fallback={<Loading height={'h-[420px]'}></Loading>}>
                    {market_copy_info.render && learning_resources ?   
                        <LearningCenter openVideo={openVideo} market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links} learning_resources={learning_resources} set_curr_video={set_curr_video} set_video_open={set_video_open}></LearningCenter>
                        :
                        <div className='h-[420px]'></div>
                    }
                    </Suspense>    
                </LazyLoad>
                
                <hr id='Ai-Chat' className="h-0.5 bg-white border-white mb-16 bg-slate-200"></hr>
                <LazyLoad height={420} once>
                    <Suspense fallback={<Loading height={'h-[420px]'}></Loading>}>
                    {market_copy_info.render ?   
                        <AiChat market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links} determine_ai_chat_url={determine_ai_chat_url}></AiChat>
                        :
                        <div className='h-[420px]'></div>
                    }
                    </Suspense>    
                </LazyLoad>
                
                <hr id='Cities' className="h-0.5 bg-white border-white mb-16 bg-slate-200"></hr>
                <LazyLoad height={684} once>
                    <Suspense fallback={<Loading height={'h-[684px]'}></Loading>}>
                        {market_copy_info.render ?
                        <Cities market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links} openForm={createForm}/>
                        :
                        <div className='h-[420px]'></div>
                        }
                    </Suspense>
                </LazyLoad>
                
                <hr id='Associations' className="h-0.5 bg-white border-white mb-16 bg-slate-200"></hr>
                <LazyLoad height={210}>
                    <Suspense fallback={<Loading height={'h-[210px]'}></Loading>}>
                        {market_copy_info.render ?
                        <Associations market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links}/>
                        :
                        <div className='h-[210px]'></div>
                        }
                    </Suspense>
                </LazyLoad>
                
                <hr id='About-Us' className="h-0.5 bg-white border-white mb-16 bg-slate-200 mt-16"></hr>
                <LazyLoad height={472}>
                    <Suspense fallback={<Loading height={'h-[472px]'}></Loading>}>
                        {market_copy_info.render ?
                        <AboutUs market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links}/>
                        :
                        <div className='h-[472px]'></div>
                        }
                    </Suspense>
                </LazyLoad>
                
                <hr id='Contact-Us' className="h-0.5 bg-white border-white mb-16 bg-slate-200 mt-16"></hr>
                <LazyLoad height={744} once>
                    <Suspense fallback={<Loading height={'h-[744px]'}></Loading>}>
                        {market_copy_info.render ?
                        <div>
                            <ContactUs market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links}></ContactUs>
                            <ContactUsButtons openForm={createForm}/>
                        </div>
                        :
                        <div className='h-[744px]'></div>
                        }
                    </Suspense>    
                </LazyLoad>
                
                 <hr id='Footer' className="h-0.5 bg-transparent border-transparent bg-slate-200"></hr>
                <LazyLoad height={262} once>
                    <Suspense fallback={<Loading height={'h-[262px]'}></Loading>}>
                        {market_copy_info.render ?
                        <Footer market_copy_info={market_copy_info} marketing_copy_links={marketing_copy_links} terms_deep={terms_deep} privacy_deep={privacy_deep} data_deep={data_deep}></Footer>
                        :
                        <div className='h-[262px]'></div>
                        }
                    </Suspense>    
                </LazyLoad>
            </div>
        </div>
    );
}


export default App;
