 import { useState,useEffect } from 'react';
 
 function Admin(props) {
    
    const [open,setOpen] = useState(false); 
    
    const [phoneValue,setPhoneValue] = useState(window.localStorage.getItem('cl_lead_phone') || '');
    const [loginFeedback,setLoginFeedback] = useState(null);
    const [emailValue,setEmailValue] = useState(window.localStorage.getItem('cl_lead_email') || '');
    const [ccUUID,setccUUID] = useState(window.localStorage.getItem('cl_lead_cc_uuid') || 'No cc_uuid');
    const [name,setName] = useState(window.localStorage.getItem('cl_lead_first_name') || '');
    
    async function validatePhone(phoneInput){
        try{
            const nonNumberPattern = /[^0-9]/g;
            let number = phoneInput.replace(nonNumberPattern, '');
            if(number.length == 11){
                number = number.substring(1);
            }
            const validatedPhone = await window.validate_phone(number); 
            
            console.log(validatedPhone.phone_e164)
            return validatedPhone.phone_e164;
        }catch(e){
            console.log(e);
            return null;
        }
        
    }
    
    async function loginThroughPhone(){
        let e164Phone = await validatePhone(phoneValue);
        if(!e164Phone){
            setLoginFeedback('Unable to Validate Phone');
            return ;
        }
        if(phoneValue && phoneValue != ''){
            let res = await window.login_lead(e164Phone,emailValue,name);
            console.log(res);
            if(res.success){
                setLoginFeedback('Login Success');
            }else{
                setLoginFeedback('Unable to Login');
            }
            refreshUserData();
            
            
            /*
            let phone_val = phoneValue;
            try{
                let phone_res = await window.validate_phone(phoneValue);
                if(phone_res.phone_e164){
                    phone_val = phone_res.phone_e164;
                }
            }catch(e){}
            await window.login_lead(phone_val,emailValue,name);
            refreshUserData(true);
            */
        }
    }
    
    async function loginThroughEmail(){
        if(emailValue && emailValue != ''){
            let phone_val = '';
            if(phoneValue && phoneValue != ''){
                let e164Phone = await validatePhone(phoneValue);
                if(!e164Phone){
                    setLoginFeedback('Unable to Validate Phone');
                    return ;
                }
            }
            let res = await window.login_lead(phone_val,emailValue,name);
            console.log(res);
            if(res.success){
                setLoginFeedback('Login Success');
            }else{
                setLoginFeedback('Unable to Login');
            }
            refreshUserData();
        }
    }
    
    async function updateInfo(){
        await window.login_lead(phoneValue,emailValue,name);
        refreshUserData(false,false);
    }
    
    function refreshUserData(skipPhone=false,skipEmail=false){
        
        setccUUID(window.localStorage.getItem('cl_lead_cc_uuid') || 'No cc_uuid');
        setName(window.localStorage.getItem('cl_lead_first_name') || '');
        if(!skipPhone){
            setPhoneValue(window.localStorage.getItem('cl_lead_phone') || '');
        }
        if(!skipEmail){
            setEmailValue(window.localStorage.getItem('cl_lead_email') || '');
        }
    }
    
    useEffect(()=>{
        refreshUserData();
    },[open]);
    //window.login_lead(phone = '', email = '', first_name = '', message = '', sub_source = '',base64quotePDF)
     
 
    return (
        <div id='admin_div'>
        { open ? 
            <div className='fixed border bg-white rounded border-black top-[75px] right-[10px] z-[1231231]'>
                <button onClick={()=>{setOpen(false)}} className='border border-black ml-[5px] mt-[5px] p-[5px] bg-slate-400 rounded'>Close</button>
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] bg-lime-500 rounded' onClick={window.visitor_api_reset}>Vistor API Reset</button>  
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] bg-amber-300 rounded' onClick={window.lead_api_reset}>Lead API Reset</button>
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] bg-amber-300 rounded' onClick={()=>{window.logout_lead()}}>Lead Logout</button>
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] bg-orange-500 rounded' onClick={window.quoting_api_reset}>Quote API Reset</button>
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] bg-orange-500 rounded' onClick={window.toggle_quote_admin}>Quote Toggle User/Admin Context</button>
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] bg-pink-700 rounded' onClick={()=>{window.load_marketing_copy_elements(true)}}>Refresh Marketing Copy Elemets</button> 
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] bg-pink-700 rounded' onClick={window.toggle_marketing_copy_version}>Toggle Marketing Copy Variation</button> 
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] bg-pink-700 rounded' onClick={window.toggle_marketing_copy_links}>Toggle Marketing Copy Editor</button> 
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] bg-red-700 rounded' onClick={window.cl_local_storage_reset}>CL Local Storage Reset</button>   
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] bg-red-700 rounded' onClick={window.local_storage_reset}>All Local Storage Reset</button>
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] mb-[5px] bg-purple-700 rounded' onClick={window.cookie_api_reset}>CL Cookie Reset</button>
                <br/>
                <button className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[5px] bg-red-700 rounded' onClick={window.admin_mode_reset}>Reset Admin</button>  
                {loginFeedback &&
                    <p className="text-xs text-stone-700 text-justify ml-[5px]">{loginFeedback}</p>
                }
                <p className="text-xs text-stone-700 text-justify ml-[5px]">{ccUUID}</p>
                
                <div className='flex'>
                    <input placeholder="No Name" onChange={(e)=>{setName(e.target.value)}} className='ml-[5px] border-black border w-[120px] text-sm' value={name}></input>
                    {ccUUID != 'No cc_uuid' &&
                    <button onClick={updateInfo} className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[2px] bg-lime-500 rounded text-sm'>Update Info</button>
                    }
                </div>
                
                <div className='flex'>
                    <input placeholder="No Phone" onChange={(e)=>{setPhoneValue(e.target.value)}} className='ml-[5px] border-black border w-[120px] text-sm' value={phoneValue}></input>
                    {ccUUID == 'No cc_uuid' &&
                    <button onClick={loginThroughPhone} className='border border-black mt-[5px] ml-[5px] mr-[5px] p-[2px] bg-lime-500 rounded text-sm'>Login</button>
                    }
                </div>
                <div className='flex'>
                    <input placeholder="No Email" onChange={(e)=>{setEmailValue(e.target.value)}} className='ml-[5px] border-black border w-[120px] text-sm' value={emailValue}></input>
                </div>
            </div>
        :
            <button onClick={()=>{setOpen(true)}} className='fixed border bg-amber-300 border-black top-[70px] right-[10px] ml-[5px] p-[5px] rounded z-[1231231]'>
                Admin
            </button>
        }
        </div>
    );
}
//<p className="text-xs text-stone-700 text-justify ml-[5px]">{window.localStorage.getItem('cl_lead_phone') || 'No phone'}</p>

export default Admin;
